import React from 'react';
import PropTypes from 'prop-types';
import {
  childrenType
} from '../propTypes';

import MuiTypography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: ({altFont, pre}) => ({
    fontFamily: altFont ? theme.altFontFamily : null,
    whiteSpace: pre ? 'pre' : undefined
  })
}));

const propTypes = {
  align: PropTypes.oneOf(['inherit', 'left', 'right', 'center', 'justify']),
  className: PropTypes.string,
  component: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span']),
  color: PropTypes.oneOf(['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error']),
  display: PropTypes.oneOf(['initial', 'block', 'inline']),
  noWrap: PropTypes.bool,
  pre: PropTypes.bool,
  paragraph: PropTypes.bool,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly', 'inherit']),
  altFont: PropTypes.bool,
  children: childrenType
}

export default function Typography({
  align = undefined,
  className = undefined,
  component = undefined,
  color = undefined,
  display = undefined,
  noWrap = false,
  pre = false,
  paragraph = true,
  variant = undefined,
  altFont = false,
  children
}) {
  const classes = useStyles({altFont, pre});
  const props = {align, component, color, display, noWrap, paragraph, variant};
  return (
    <MuiTypography { ...props } className={`${classes.root} ${className}`}>{children}</MuiTypography>
  );
}

Typography.propTypes = propTypes;
