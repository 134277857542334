import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MuiTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Link from "./Link";

import { formFieldStyles } from './styles';

const propTypes = {
  autocomplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  displayErrorOnChange: PropTypes.bool,
  errorText: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  helperTextUrl: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  password: PropTypes.bool,
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  suffix: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default function TextField({
  autocomplete = undefined,
  autoFocus = false,
  disabled = false,
  displayErrorOnChange = false,
  errorText = undefined,
  fullWidth = false,
  helperText = undefined,
  helperTextUrl = undefined, // Only if the helperText should be a link
  id,
  label = 'A default label',
  name = undefined,
  password = false,
  prefix = undefined,
  onChange = (event) => console.log('onChange', event.target.value),
  onBlur = () => {},
  onFocus = () => {},
  required = false,
  suffix = undefined,
  valid = false,
  value = undefined,
  width = undefined
}) {
  const classes = formFieldStyles({width, valid});
  const [state, setState] = React.useState({focus: false, showPassword: false});
  const [labelWidth, setLabelWidth] = React.useState(0);
  const inputLabel = React.useRef(null);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current ? inputLabel.current.offsetWidth : 0);
  }, [label]);

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  if(password) {
    const hintText = Boolean(errorText) && (!state.focus || displayErrorOnChange) ? errorText : helperText;
    return (
      <FormControl
        className={classes.field}
        fullWidth={fullWidth}
        variant="outlined"
        required={required}
        error={(!state.focus || displayErrorOnChange) && Boolean(errorText)}
        disabled={disabled}
      >
        <InputLabel ref={inputLabel} htmlFor="outlined-adornment-password">{label}</InputLabel>
        <OutlinedInput
          inputProps={{autoComplete: autocomplete, id: id}}
          name={name}
          type={state.showPassword ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={labelWidth}
          onBlur={event => { setState({...state, focus: false}); onBlur(event); }}
          onFocus={event => { setState({...state, focus: true}); onFocus(event); }}
        />
        {hintText && <FormHelperText>{helperTextUrl ? <Link disabled={disabled} to={helperTextUrl}>{hintText}</Link>: hintText }</FormHelperText>}
      </FormControl>
    );
  }

  return (
    <MuiTextField
      className={classes.field}
      id={id}
      autoFocus={autoFocus}
      name={name}
      disabled={disabled}
      label={label}
      value={value}
      helperText={Boolean(errorText) && (!state.focus || displayErrorOnChange) ? errorText : helperText}
      InputProps={{
        startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : undefined,
        endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : undefined,
      }}
      margin="normal"
      variant="outlined"
      required={required}
      error={(!state.focus || displayErrorOnChange) && Boolean(errorText)}
      fullWidth={fullWidth}
      onChange={onChange}
      onBlur={event => { setState({...state, focus: false}); onBlur(event); }}
      onFocus={event => { setState({...state, focus: true}); onFocus(event); }}
      color="primary"
    />
  );
};

TextField.propTypes = propTypes;
