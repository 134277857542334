import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', null]),
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  imgClassName: PropTypes.string,
  imgStyle: PropTypes.object
};

export default function AlignableImage({align = 'left', src, alt, imgClassName = null, imgStyle}) {
  return (
    <div style={align ? {textAlign: align} : undefined} className="alignable-image"><img className={imgClassName} src={src} alt={alt} style={imgStyle}/></div>
  );
};

AlignableImage.propTypes = propTypes;
