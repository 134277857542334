import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';

import Typography from '../components/Typography';
import LanguageSelector from '../components/LanguageSelector';
import ApiErrorAlert from '../components/ApiErrorAlert';
import CenteredPanelPage from '../components/CenteredPanelPage';
import CircularProgress from '../components/CircularProgress.js';
import Button from '../components/Button';

import { getLanguage } from '../utils/getLanguage';

import * as cookie from '../utils/cookieManager';

import {
  redirectToAccountUrl,
  SIGNIN_ACTION_ID,
  signInOIDC,
  signOut
} from '../reducers/auth';

import { getMsTeamsContext, getMsTeamsOrStandalonePath } from '../utils/getMsTeamsContext';

import { COMING_FROM_PAGE_PARAM } from '../constants';


const useStyles = makeStyles(theme => {
  return {
    errorAndLink: {
      paddingTop: theme.spacing(2)
    }
  }
});


const propTypes = {
  emailHint: PropTypes.string,
  extraHeaders: PropTypes.object,
  noCreateAccountLink: PropTypes.bool
}

export default function SignInOIDC({extraHeaders = null}) {
  const [tr, i18n] = useTranslation();
  const language = getLanguage();
  const dispatch = useDispatch();

  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);

  const [msTeamsHeaders, setMsTeamsHeaders] = useState(undefined);
  const [msTeamsContext, setMsTeamsContext] = useState(null);
  const [isGettingTeamsContext, setGettingTeamsContext] = useState(false);

  const [state, setState] = useState({nextRedirection: null});

  const working = auth.actionState === 'working';
  const isSigninActionInError = (auth.action === SIGNIN_ACTION_ID && auth.actionError === 'failed') ? true : false;
  let signInExtraHeaders = extraHeaders || {};
  const currentPageLocation = useLocation();
  const classes = useStyles();
  const history = useHistory();

  // Build extra headers used to send an API call to sign the user in
  if (auth.cocoomAccountUri) {
    signInExtraHeaders['x-cocoom-uri'] = auth.cocoomAccountUri;
  }/*  else if (cookie.getCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME)) {
    signInExtraHeaders['x-cocoom-uri'] = cookie.getCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME);
  } */
  if (msTeamsHeaders) {
    signInExtraHeaders = { ...signInExtraHeaders, ...msTeamsHeaders};
  }


  useEffect(() => {
    setGettingTeamsContext(true);

    // alert('SIGN-IN OIDC | Getting MSTeams context');

    if (!msTeamsContext) {
      const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);

      getMsTeamsContext({encodedData: msTeamsOIDCSigninCookie, onContext: (context) => {
        setGettingTeamsContext(false);
        setMsTeamsContext(context);

        // alert('SIGN-IN OIDC | getMsTeamsContext | context: ' + JSON.stringify(context));

        const additionalHeaders = {
          aad_tid: context.tid,
          aad_uid: context.userObjectId,
        };

        const cookieFromAssociationMode = cookie.getCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
        if (cookieFromAssociationMode) {
          additionalHeaders.association_mode = true;
        } else {
          additionalHeaders.aad_gid = context.groupId;
        }

        setMsTeamsHeaders({
          'x-cocoom-msteams': btoa(unescape(encodeURIComponent(JSON.stringify(additionalHeaders))))
        });
      }});
    }
  }, [msTeamsContext]);


  useEffect(() => {
    const signInSucceed = auth.action === SIGNIN_ACTION_ID && auth.actionState === 'done';

    const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
    // alert('msTeamsOIDCSigninCookie = ' + msTeamsOIDCSigninCookie);

    if (signInSucceed) {
      cookie.resetCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
      cookie.resetCookie(cookie.MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME);

      if (!msTeamsContext) {
        if (state.nextRedirection) {
          dispatch(redirectToAccountUrl(state.nextRedirection));
          return;
        }

        if (auth.cocoomAccountUri) {
          dispatch(redirectToAccountUrl(auth.cocoomAccountUri));
          return;
        }
      }

      if (auth.user && auth.user.accounts && auth.user.accounts.length >= 1) {
        // alert('SEVERAL ACCOUNTS | SIGNIN OIDC MSTEAMS_OIDC_COOKIE_NAME cookie value = ' + msTeamsOIDCSigninCookie);
        if (msTeamsContext && msTeamsOIDCSigninCookie) {
          if (cookie.getCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME)) {
            const redirectionUrl = '/msteams/associate?next=&d=' + msTeamsOIDCSigninCookie;
            // alert('REDIRECT TO ' + redirectionUrl);
            window.location.replace(redirectionUrl);
            return;
          }

          cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
        } else {
          // alert('GO TO /signin route when sign-in successfull');
          window.location.replace('/signin?next=');
          return;
        }
      }

      if (auth.user && (msTeamsContext || msTeamsOIDCSigninCookie)) {
        // alert('OIDC END | MS TEAMS context | Close the window');
        // microsoftTeams.authentication.notifySuccess();
        cookie.resetCookie(cookie.MSTEAMS_SIGNEDIN_COOKIE_NAME);
        window.close();
        return null;
      }
    }
  }, [dispatch, msTeamsContext, auth.user, auth.actionError, auth.cocoomAccountUri, auth.action, auth.actionState, currentPageLocation, state.nextRedirection]);


  useEffect(() => {
    const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);

    if (auth.action !== SIGNIN_ACTION_ID) {
      if (signInExtraHeaders && ( (msTeamsOIDCSigninCookie && msTeamsContext && !isGettingTeamsContext && msTeamsHeaders) || !msTeamsOIDCSigninCookie )) {
        const query = queryString.parse(currentPageLocation.search);
        // alert('QUERY: ' + JSON.stringify(query, null, 2));
        if (query.code && query.state && !query.error) {
          dispatch(signInOIDC(query.code, query.state, signInExtraHeaders));
        } else {
          /* On sign in rejected (error, no access), then this object is returned
            {
              "error": "access_denied",
              "error_subcode": "cancel",
              "state": "Isr0d4z5fZBwLRT"
            }
          */
        //  alert('go to /sign in when not a sign in action triggered');
         window.location.replace(getMsTeamsOrStandalonePath('/signin' + (window.location.search ? window.location.search : ''), msTeamsContext));
        }
      }
    }
  }, [auth.action, msTeamsContext, msTeamsHeaders, signInExtraHeaders, currentPageLocation.search, isGettingTeamsContext, dispatch]);


  useEffect(() => {
    let redirection;
    if (currentPageLocation.search.indexOf(COMING_FROM_PAGE_PARAM) > 0) {
      const urlParams = queryString.parse(currentPageLocation.search);
      redirection = urlParams[COMING_FROM_PAGE_PARAM];
    } else if (cookie.getCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME)) {
      redirection =  cookie.getCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME);
    }

    // console.log('Set next redirection = ' + redirection);
    setState({nextRedirection: redirection});
  }, [currentPageLocation.search]);


  function closeWindow() {
    const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
    cookie.resetCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
    cookie.resetCookie(cookie.MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME);

    if (msTeamsContext || msTeamsOIDCSigninCookie) {
      // microsoftTeams.authentication.notifyFailure(auth.actionError);
      window.close();
      return null;
    }
  }

  function changeLanguage(language) {
    i18n.changeLanguage(language);
  };

  function onRequestAccess() {
    history.push('/accessRequest' + (window.location.search ? window.location.search : ''));
  }

  function getErrorAlert() {
    if (auth.actionState === 'failed') {
      let contentOnError = null;

      const statusCode = auth.actionError.statusCode;
      let signinErrorEmail;
      try {
        signinErrorEmail = auth.actionError.body ? JSON.parse(auth.actionError.body).email : null;
      } catch (parsingError) {}

      if (auth.action === SIGNIN_ACTION_ID) {
        if (statusCode === 401) {
          contentOnError = (
            <div className={classes.errorAndLink}>
              <ApiErrorAlert message={signinErrorEmail ? tr('signinOidc.authFailedEmail', {email: signinErrorEmail}) : tr('signinOidc.authFailed')}/>
              { /* We must use an absolute path URL because some server side execution need to be done; it can't be a client-side relative hyperlink (such as  <Link>) */}
              {customization.accessRequest.accessRequestRecipient &&
                <div style={{textAlign: 'center'}}>
                  <Typography component="span" altFont>{tr('signin.form.requestAnAccessText')}</Typography>
                  <Button id="buttonToRequestAccess" label={tr('signin.form.requestAnAccessButton')} onClick={() => onRequestAccess()} />
                </div>
              }
            </div>
          );
        } else {
          contentOnError =  (
            <div className={classes.errorAndLink}>
              <ApiErrorAlert statusCode={statusCode} message={auth.actionError.text} body={auth.actionError.body}/>
              { /* We must use an absolute path URL because some server side execution need to be done; it can't be a client-side relative hyperlink (such as  <Link>) */}
              {!msTeamsContext &&
                <a href={'/signin' + (window.location.search ? window.location.search : '')}>
                  {tr('general.back')}
                </a>
              }
            </div>
          );
        }
      }

      return (
        <div style={{textAlign: 'center'}}>
          {contentOnError}
          {msTeamsContext &&
            <a href="." onClick={() => closeWindow()}>
              {tr('general.close')}
            </a>
          }
        </div>
      )
    }

    return null;
  }


  return (
    <React.Fragment>
      <CenteredPanelPage
        backgroundColor={customization.backgroundColor}
        backgroundImage={customization.backgroundImage}
        headerBar={!working && (
          <div style={{textAlign: 'right'}}>
            <LanguageSelector language={language} onChange={changeLanguage}/>
          </div>
        )}
        topLogo={customization.topLogo}
        topLogoAlt={customization.topLogoAlt}
        topLogoInPanel={customization.topLogoInPanel}
        imageAsTitleAlt={customization.signIn.title.imgAlt}
      >
        {getErrorAlert()}
        {auth.user && !working && isSigninActionInError &&
          <Button id="buttonToSignOut" label={tr('general.signout')} onClick={() => signOut()} />
        }
        {working && <div style={{textAlign: 'center'}}><CircularProgress /></div>}

      </CenteredPanelPage>
    </React.Fragment>
  );
}

SignInOIDC.propTypes = propTypes;
