export function adaptAuthentificationData(data) {
  return {user: {
    accounts: data.user.accounts,
    avatarUrl: data.user.avatar_url,
    email: data.user.email,
    firstname: data.user.first_name,
    ref: data.user.ref,
    isInvited: data.invited,
    isAdmin: data.user.isadmin,
    language: data.user.language,
    lastname: data.user.last_name,
    roles: data.user.roles,
    userId: data.user.userid
  }};
}
