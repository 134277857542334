import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import MuiSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.dark
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  }
}));

export default function Snackbar({variant, open, message, anchorOrigin, autoHideDuration, onClose}) {
  const classes = useStyles();

  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
        <SnackbarContent
        className={classes[variant]}
        aria-describedby="error-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  open: PropTypes.bool,
  anchorOrigin: PropTypes.object,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.oneOf(['error']),
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func
}

Snackbar.defaultProps = {
  open: false,
  anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
  autoHideDuration: 6000,
  variant: 'error',
  onClose: () => {}
}
