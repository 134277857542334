import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import CircularProgess from '../../components/CircularProgress';

import SignIn from '../SignIn';
import AssociationRequest from './AssociationRequest';

import * as cookie from '../../utils/cookieManager';

import {
  SIGNIN_ACTION_ID
} from '../../reducers/auth';
import {
  COMING_FROM_PAGE_PARAM
} from '../../constants';
import { getMsTeamsContext } from '../../utils/getMsTeamsContext';

export const ASSOCIATION_MODE = '/cocoom/SignInForMsTeams/ASSOCIATION_MODE';

const propTypes = {
  mode: PropTypes.oneOf([ASSOCIATION_MODE])
}


export default function SignInForMsTeams({mode = null}) {
  const auth = useSelector(state => state.auth);
  const [msTeamsHeaders, setMsTeamsHeaders] = useState(undefined);
  const [isGettingTeamsContext, setGettingTeamsContext] = useState(false);
  const [msTeamsContext, setMsTeamsContext] = useState(null);
  const currentPageLocation = useLocation();
  const [associationView, showAssociationView] = useState(false);

  const signInSucceed = auth.action === SIGNIN_ACTION_ID && auth.actionState === 'done';
  const signInFailed = auth.action === SIGNIN_ACTION_ID && auth.actionState === 'failed';

  if (mode !== ASSOCIATION_MODE) {
    cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
  }

  function handleClose() {
    // alert('sign in for MSTEAMS on close called');
    window.close();
    return null;
  }

  useEffect(() => {
    // alert('MODE= ' + mode + '; location path = ' + currentPageLocation.pathname + '; location search part = ' + currentPageLocation.search);
    // alert('SIGN-IN FOR MSTEAMS | Getting MSTeams context');
    if (!msTeamsContext) {
      setGettingTeamsContext(true);
      getMsTeamsContext({location: {pathname: currentPageLocation.pathname, search: currentPageLocation.search}, onContext: (context) => {
          // Change the state by saying that we now get the Teams context, so the component can render accordingly
          setGettingTeamsContext(false);
          // Set the Teams context for this component for further usages
          setMsTeamsContext(context);

          // alert('SIGN-IN FOR MSTEAMS | getMsTeamsContext | context: ' + JSON.stringify(context));

          const additionalHeaders = {
            aad_tid: context.tid,
            aad_uid: context.userObjectId,
          };

          if (mode === ASSOCIATION_MODE) {
            cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
            cookie.setCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME, 'association-mode', 1200); // Expires in 20 minutes

            additionalHeaders.association_mode = true;
            // alert('Signin in Teams | Assoc. MODE ! ' + JSON.stringify(additionalHeaders));
          } else {
            additionalHeaders.aad_gid = context.groupId;
            // alert('Signin in Teams | Got Teams context ! ' + JSON.stringify(additionalHeaders));
          }

          setMsTeamsHeaders({
            'x-cocoom-msteams': btoa(unescape(encodeURIComponent(JSON.stringify(additionalHeaders))))
          });
        }
      });
    }
  }, [currentPageLocation.pathname, currentPageLocation.search, mode, msTeamsContext]);


  useEffect(() => {
    // console.log('   SignInForMsTeams -------------- ????????? Show association view ??????????? ' + associationView + '; account URL = ' + auth.targetAccountUrl + ' (mode=' + mode + ')');
    if (auth.targetAccountUrl && !associationView && mode === ASSOCIATION_MODE) {
      // console.log('   SignInForMsTeams -------------- Show association view (set to true)');
      showAssociationView(true);
    }
  }, [mode, auth.targetAccountUrl, associationView]);


  useEffect(() => {
    if (!isGettingTeamsContext && msTeamsContext) {
      // alert('Signin for MSTeams auth.authMode = ' + auth.authMode + ' | currentPageLocation.search = ' + currentPageLocation.search + ' | msTeamsContext = ' + msTeamsContext + ' | isGettingTeamsContext = '  + isGettingTeamsContext);

      const urlParams = queryString.parse(currentPageLocation.search);
      if (urlParams) {
        // Set Cookie to let know this is the beginning of an OIDC sign in workflow started from MS Teams
        const encodedMsTeamsContext = urlParams.d;
        // alert('SIGN IN MS TEAMS | Encoded MS Teams context: ' + encodedMsTeamsContext);
        cookie.resetCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
        cookie.setCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME, encodedMsTeamsContext, 1200); // Expires in 20 minutes
      }

      if (!signInSucceed && mode !== ASSOCIATION_MODE) {
        cookie.resetCookie(cookie.MSTEAMS_SIGNEDIN_COOKIE_NAME);
        cookie.setCookie(cookie.MSTEAMS_SIGNEDIN_COOKIE_NAME, 'signedin-from-msteams', 1200);
      }

      if (auth.authMode === 'oidc' &&
        (currentPageLocation.search.indexOf(COMING_FROM_PAGE_PARAM) > 0)
      ) {
        const urlParams = queryString.parse(currentPageLocation.search);
        const next = urlParams[COMING_FROM_PAGE_PARAM];

        // if (!isGettingTeamsContext && msTeamsContext) {
        cookie.resetCookie(cookie.MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME);
        cookie.setCookie(cookie.MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME, next, 1200); // Expires accordingly in 20 minutes (used by Start server to let it know the requested redirection)
        // }

        let promptForCredentialsQuery = '';
        if (window.location.search.indexOf('prompt') > -1) {
          promptForCredentialsQuery = 'prompt=' + urlParams['prompt'];
        }

        // alert(window.location.pathname + '      ||||||||||||||      SIGN IN MS TEAMS | Redirect to /sign path');

        let queryPart = (next ? '?next=' + next : '');
        if (promptForCredentialsQuery) {
          if (queryPart) {
            queryPart += '&';
          } else {
            queryPart += '?';
          }
          queryPart += promptForCredentialsQuery;
        }

        const url = '/signin' + queryPart;
        // alert('   SignInForMsTeams -------------- Redirect to /signin page');
        window.location.replace(url);
        return;
      }
    }
  }, [auth.authMode, currentPageLocation.search, mode, signInSucceed, msTeamsContext, isGettingTeamsContext]);


  // console.log('   SignInForMsTeams -------------- auth.authMode = ' + auth.authMode + '; mode = ' + mode + '; associationView = ' + associationView + ', getting teams ctx ? ' + isGettingTeamsContext + ', msTeamsContext = ' + JSON.stringify(msTeamsContext));

  if ((mode === ASSOCIATION_MODE) && !isGettingTeamsContext && associationView && msTeamsContext && auth.user && auth.targetAccountUrl) {
    // alert('   SignInForMsTeams -------------------- Display ASSOCIATION VIEW');
    return <AssociationRequest
      email={auth.user && auth.user.email}
      cocoomAccountUri={auth.targetAccountUrl}
      tid={msTeamsContext.tid}
      target="msteams"
      onClose={handleClose}
    />;
  }

  if (auth.authMode !== 'oidc' && (!signInSucceed || mode === ASSOCIATION_MODE)) {
    // alert('   SignInForMsTeams -------------- Display Sign in component');
    return <SignIn emailHint={msTeamsContext ? msTeamsContext.loginHint : ''} extraHeaders={msTeamsHeaders} noCreateAccountLink useCocoomAccountUri={mode !== ASSOCIATION_MODE} isAssociationMode={mode === ASSOCIATION_MODE} teamsContext />;
  }

  if (signInSucceed || (auth.authMode === 'oidc' && auth.user)) {
    // alert('   SignInForMsTeams -------------- Sign in SUCCESS');
    cookie.resetCookie(cookie.MSTEAMS_SIGNEDIN_COOKIE_NAME);
    return handleClose();
  }

  if (signInFailed && auth.actionError.statusCode !== 401) {
    // alert('   SignInForMsTeams -------------- Sign in ERROR');
    cookie.resetCookie(cookie.MSTEAMS_SIGNEDIN_COOKIE_NAME);
    return handleClose();
  }

  // console.log('   SignInForMsTeams -------------- Sign-in in progress...');
  return  <CircularProgess fullPage/>;
}

SignInForMsTeams.propTypes = propTypes;
