import React from 'react';
import PropTypes from 'prop-types';
import {
  childrenType
} from '../propTypes';

import AlignableImage from '../components/AlignableImage';
import Page from '../components/Page';
import PageRow from '../components/PageRow';
import Paper from '../components/Paper';
import Typography from '../components/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    title: {
      textAlign: 'center',
      fontWeight: 300,
      marginTop: 0
    },
    titleImage: {
      maxWidth: 200,
      maxHeight: 50,
      margin: theme.spacing(1)
    },
    titleWithoutImage: {
      minHeight: 30,
      margin: theme.spacing(1)
    },
    toplogo: {
      maxWidth: 200,
      maxHeight: 70,
      margin: theme.spacing(3)
    }
  }
});

const propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  children: childrenType,
  headerBar: PropTypes.element,
  imageAsTitle: PropTypes.string,
  imageAsTitleAlt: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  topLogo: PropTypes.string,
  topLogoAlt: PropTypes.string,
  topLogoInPanel: PropTypes.bool,
  fullWidth: PropTypes.bool
}

export default function CenteredPanelPage({
  backgroundColor = undefined,
  backgroundImage = undefined,
  children,
  headerBar = undefined,
  imageAsTitle = undefined,
  imageAsTitleAlt = undefined,
  subtitle = undefined,
  title = undefined,
  topLogo = undefined,
  topLogoAlt = undefined,
  topLogoInPanel = false,
  fullWidth = false
  }) {
  const classes = useStyles();


  function getSignInContent(classes) {
    return (
      <Paper elevation={fullWidth ? 0 : 2} style={{height: fullWidth ? '100%' : undefined}}>
        {headerBar && <div style={{position: 'absolute', top: 0, left: 0, width: '100%'}}>
          {headerBar}
        </div>}

        {topLogoInPanel && <AlignableImage align="center" imgClassName={classes.toplogo} src={topLogo} alt={topLogoAlt} imgStyle={{paddingTop: 12}} />}
        {!topLogoInPanel && <div className={classes.titleWithoutImage} />}

        {imageAsTitle && <AlignableImage align="center" imgClassName={classes.titleImage} src={imageAsTitle} alt={imageAsTitleAlt}/>}

        {subtitle && <Typography component="h2" variant="h6" altFont className={classes.title}>{title ? title + '. ' : ''}{subtitle}</Typography>}

        {children}
      </Paper>
    );
  }

  return (
    <div style={{height: '100%'}}>
      {fullWidth ?
        getSignInContent(classes)
        :
        (<Page maxWidth="sm" fullHeight centeredContent backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
          <PageRow nbColumnsXs={1} centered>
            {!topLogoInPanel && topLogo && <img src={topLogo} className={classes.toplogo} alt={topLogoAlt}/>}

            {getSignInContent(classes)}
          </PageRow>
        </Page>
        )
      }
    </div>
  );
}

CenteredPanelPage.propTypes = propTypes;
