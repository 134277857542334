import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = backgroundImageUrl => makeStyles(theme => ({
  pageHeader: {
    margin: theme.spacing(0, 0),
    backgroundImage: backgroundImageUrl ? `url('${backgroundImageUrl}')` : undefined,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    padding: theme.spacing(0, 0, 0, 0),
  },
  surtitle: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'uppercase',
    color: '#0056ec'
  },
  title: {
    fontFamily: 'Volte, Sans-serif',
    fontSize: 39,
    fontWeight: 900,
    color: '#2e3192'
  },
  subtitle: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 20,
    fontWeight: 500,
    color: '#2e3192'
  },
  logo: {
    width: 45,
    height: 45,
    borderRadius: 4,
    overflow: 'hidden'
  }
}))();

export default function PageHeader({ surtitle, title, subtitle, backgroundImageUrl }) {
  const classes = useStyles(backgroundImageUrl);
  return (
    <div className={classes.pageHeader}>
      <div style={{textAlign: 'center'}}><img className={classes.logo} src="/logo192.png" alt="Cocoom logo"/></div>
      {surtitle && <Typography component="h6" className={classes.surtitle} align="center">{surtitle}</Typography>}
      <Typography component="h1" className={classes.title} align="center">{title}</Typography>
      {subtitle && <Typography component="h2" className={classes.subtitle} align="center">{subtitle}</Typography>}
    </div>
  );
}

PageHeader.propTypes = {
  surtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backgroundImageUrl: PropTypes.string
}

PageHeader.defaultProps = {
  surtitle: undefined,
  title: 'A default title',
  subtitle: undefined,
  backgroundImageUrl: undefined
}
