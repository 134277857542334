import React from 'react';
import PropTypes from 'prop-types';

import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import { formFieldStyles } from './styles';

export default function Select({id, label, fullWidth, value, values, helperText, required, errorText, onChange}) {
  const classes = formFieldStyles({fullWidth: fullWidth});
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, [label]);

  return (
    <FormControl className={classes.field} id={id} variant="outlined" required={required} error={Boolean(errorText)} fullWidth={fullWidth}>
      <InputLabel ref={inputLabel} htmlFor={id + '-select'}>
        {label}
      </InputLabel>
      <MuiSelect
        value={value}
        onChange={onChange}
        labelWidth={labelWidth}
        inputProps={{
          name: id,
          id: id + '-select',
        }}
      >
        {values.map(item => <MenuItem key={id + '_' + item.value} value={item.value ? item.value : ''}>{(item.label ? item.label : '')}</MenuItem>)}
      </MuiSelect>
      <FormHelperText>{Boolean(errorText) ? errorText : helperText}</FormHelperText>
    </FormControl>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({value: PropTypes.any, label: PropTypes.string})),
  helperText: PropTypes.string,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func
}

Select.defaultProps = {
  label: 'A default label',
  defaultValue: undefined,
  values: [],
  helperText: undefined,
  errorText: undefined,
  required: false,
  onChange: () => {}
}
