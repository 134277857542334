function resetCookie(name) {
  // alert('### reset cookie', name);
  if (document && document.cookie) {
    document.cookie = [
      `${name}=`,
      `domain=cocoom.com`,
      `expires=Thu, 01 Jan 1970 00:00:00 GMT`,
      `path=/`
    ].join(';');
  }
}

function getCookie(name) {
  let cookie;
  if (document && document.cookie) {
    // eslint-disable-next-line
    const re = new RegExp(['(?:^|; )', name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1'), '=([^;]*)'].join(''));
    const matches = document.cookie.match(re);
    if (matches) {
      cookie = decodeURIComponent(matches[1]);
    }
  }

  return cookie;
}

function setCookie(name, value, durationInSeconds) {
  if (document) {
    // Expiration date
    const expiresDateString= new Date(Date.now() + (durationInSeconds * 1000)).toUTCString(); // Expires in durationInSeconds minutes
    document.cookie = [
      `${name}=${value}`,
      `path=/`,
      `domain=cocoom.com`,
      `expires=${expiresDateString}`
    ].join(';');
  }
}

/** Constant used to reference the cookie set during OIDC workflow inside Teams */
const MSTEAMS_OIDC_COOKIE_NAME = 'msteams_oidc_signin'; // Used to store MS Teams context info along the sign-in workflow
const MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME = 'msteams_oidc_redirection'; // Used during the OIDC workflow to let the algorithm knows which Cocoom base url the user must be redirected to, on sign-in success
const MSTEAMS_SIGNEDIN_COOKIE_NAME = 'msteams_signedin'; // Indicate to Cocoom webapp that user authentication was initiated from MS Teams (used by refresh cookie to apply contextual options, including SameSite)
const SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME = 'cc-redirect';
const MSTEAMS_ASSOCIATION_COOKIE_NAME = 'msteams_association';

export {
  getCookie,
  setCookie,
  resetCookie,
  MSTEAMS_OIDC_COOKIE_NAME,
  MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME,
  MSTEAMS_SIGNEDIN_COOKIE_NAME,
  SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME,
  MSTEAMS_ASSOCIATION_COOKIE_NAME
};
