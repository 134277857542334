import React from 'react';
import { useSelector } from "react-redux";

import ApiErrorAlert from '../components/ApiErrorAlert';
import CenteredPanelPage from '../components/CenteredPanelPage';


export default function GenericError() {
  const customization = useSelector(state => state.customization);

  return (
    <React.Fragment>
      <CenteredPanelPage
        backgroundColor={customization.backgroundColor}
        backgroundImage={customization.backgroundImage}
        topLogo={customization.topLogo}
        topLogoAlt={customization.topLogoAlt}
        topLogoInPanel={customization.topLogoInPanel}
        imageAsTitle={customization.accessRequest.title.img}
        imageAsTitleAlt={customization.accessRequest.title.imgAlt}
        title="Error"
        subtitle=""
      >
        <ApiErrorAlert statusCode={404} message="Error" />
      </CenteredPanelPage>
    </React.Fragment>
  );
}
