/**
 *
 * @param {string} id Translation key
 * @param {Object} context Translation context (key with their value to replace in translated string)
 * @param {string} gridWidth Could be 'xs'
 * @param {Function} i18NextTranslationFunction
 */
export default function translateWithXsModeDistinction(id, context, gridWidth, i18NextTranslationFunction) {
  if (gridWidth === 'xs') {
    const xsId = id + '-xs';
    const str = i18NextTranslationFunction(xsId);
    if (str !== xsId) {
      return str;
    }
  }

  return i18NextTranslationFunction(id, context);
}
