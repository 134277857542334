import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    padding: theme.spacing(1)
  }
}));

export default function PageRow({ children, columnSpacing, nbColumnsXs, nbColumnsSm, nbColumnsMd, nbColumnsLg, nbColumnsXl, centered }) {
  const nbColumns = React.Children.count(children);
  if (nbColumns > 6) {
    throw new Error(nbColumns + ' is too much columns. A Page can host a max of 6 columns.')
  }

  const classes = useStyles();

  return (
    <Grid container spacing={columnSpacing} justify="center" classes={{root: classes.root}}>
      {React.Children.map(children, child => {
        return (
          <Grid item
            xs={nbColumnsXs ? Math.trunc(12 / nbColumnsXs) :  Math.trunc(12 / nbColumns)}
            sm={nbColumnsSm ? Math.trunc(12 / nbColumnsSm) : undefined}
            md={nbColumnsMd ? Math.trunc(12 / nbColumnsMd) : undefined}
            lg={nbColumnsLg ? Math.trunc(12 / nbColumnsLg) : undefined}
            xl={nbColumnsXl ? Math.trunc(12 / nbColumnsXl) : undefined}
            style={{textAlign: centered ? 'center' : undefined}}
          >
            {child}
          </Grid>
        );
      })}
      <Grid item xs={12}></Grid>
    </Grid>
  );
}

PageRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  nbColumnsXs: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  nbColumnsSm: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  nbColumnsMd: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  nbColumnsLg: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  nbColumnsXl: PropTypes.oneOf([1, 2, 3, 4, 5, 6])
}

PageRow.defaultProps = {
  nbColumnsXs: undefined,
  nbColumnsSm: undefined,
  nbColumnsMd: undefined,
  nbColumnsLg: undefined,
  nbColumnsXl: undefined
}
