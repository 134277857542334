import PropTypes from 'prop-types';

const ZXCVBN_SOURCE = '/zxcvbn.js';
const ZXCVBN_MAX_SCORE = 4;

const propTypes = {
  password: PropTypes.string,
  onScore: PropTypes.func,
  options: PropTypes.shape({
    language: PropTypes.oneOf(['fr', 'en']),
    minScore: PropTypes.oneOf([0, 1, 2, 3, 4]),
    minLength: PropTypes.number,
    expectAtLeastOneLowercase: PropTypes.bool,
    expectAtLeastOneUppercase: PropTypes.bool,
    expectAtLeastOneNumeric: PropTypes.bool,
    expectAtLeastOneSpecialChar: PropTypes.bool
  })
}

const defaultProps = {
  password: '',
  onScore: null,
  options: {
    language: 'fr',
    minScore: 3,
    minLength: 8,
    expectAtLeastOneLowercase: true,
    expectAtLeastOneUppercase: true,
    expectAtLeastOneNumeric: true,
    expectAtLeastOneSpecialChar: ')(!@#$%^&*-',
  }
}

const getFeedbackMessage = (options, score, policyValidation, acceptable) => {
  const language = options.language === 'fr' ? 'fr' : 'en';

  if (!policyValidation) {
    const minLength = options.minLength;

    const lowercase = options.expectAtLeastOneLowercase ? {
      fr: 'au moins une minuscule',
      en: 'at least a lowercase'
    } : '';

    const uppercase = options.expectAtLeastOneUppercase ? {
      fr: 'au moins une majuscule',
      en: 'at least a uppercase'
    } : '';

    const numeric = options.expectAtLeastOneNumeric ? {
      fr: 'au moins un chiffre',
      en: 'at least a number'
    } : '';

    const special = options.expectAtLeastOneSpecialChar ? {
      fr: 'au moins un caractère spécial parmi ' + options.expectAtLeastOneSpecialChar,
      en: 'at least a special character among ' + options.expectAtLeastOneSpecialChar
    } : '';

    const rules = [lowercase[language], uppercase[language], numeric[language], special[language]].join(', ');

    switch(language) {
      case 'fr': return `🚨 Votre mot de passe doit contenir au minimum ${minLength} caractères${rules ? `, dont ${rules}` : ''}.`;
      default: return `🚨 Your password must be at least ${minLength} long${rules ? `, and must contain ${rules}` : ''}.`;
    }
  }

  if (!acceptable) {
    switch(language) {
      case 'fr': return `🚨 Un pirate peut deviner trop facilement votre mot de passe.`;
      default: return `🚨 A hacker can easily guess your password.`;
    }
  }

  if (score === ZXCVBN_MAX_SCORE) {
    switch(language) {
      case 'fr': return `Excellent mot de passe ! 🟢 🤘`;
      default: return `Excellent password ! 🟢 🤘`;
    }
  }

  switch(language) {
    case 'fr': return `Votre mot de passe est plutôt sûr. 🟢`;
    default: return `You password is fairly secure. 🟢`;
  }
}

// Ugly thing to avoid usage of the useState hook, to have a code that could be also used in the manager.
const state = {};
// End of ugly thing

export default function Zxcvbn({ password, onScore, options }) {
  const opts = {...defaultProps.options, ...options};
  if (onScore) {
    if (typeof window.zxcvbn === 'undefined') {
      const scriptNode = document.createElement('script');
      scriptNode.src = ZXCVBN_SOURCE;
      scriptNode.type = 'text/javascript';
      scriptNode.async = true;
      const headNode = document.getElementsByTagName('script')[0];
      headNode.parentNode.insertBefore(scriptNode, headNode);
      return null;
    }

    // Ugly thing to avoid usage of the useState hook, to have a code that could be also used in the manager.
    if (state.password === password && state.language === opts.language) {
      return null;
    }
    state.password = password;
    state.language = opts.language;
    // End of ugly thing

    // Build validation regex according to configuration options
    const validationRegexString = '^'
      + (opts.expectAtLeastOneLowercase ? '(?=.*[a-z])' : '')
      + (opts.expectAtLeastOneUppercase ? '(?=.*[A-Z])' : '')
      + (opts.expectAtLeastOneNumeric ? '(?=.*[0-9])' : '')
      + (opts.expectAtLeastOneSpecialChar ? `(?=.*[${opts.expectAtLeastOneSpecialChar}])` : '')
      + `(?=.{${opts.minLength},})`;
    const validationRegex = new RegExp(validationRegexString);

    // Does password pass validation test ?
    let score = null;
    let policyValidation = false;
    let acceptable = false;
    if (validationRegex.test(password)) {
      // Yes, it does.
      policyValidation = true;

      // Scoring it with zxcvbn lib
      const result = window.zxcvbn(password);
      score = result.score;
      acceptable = result.score >= opts.minScore;
    }

    onScore({
      score: score,
      policyValidation: policyValidation,
      acceptable: acceptable,
      feedback: getFeedbackMessage(opts, score, policyValidation, acceptable)});
  }

  return null;
}

Zxcvbn.propTypes = propTypes;
Zxcvbn.defaultProps = defaultProps;
