import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import CenteredPanelPage from '../components/CenteredPanelPage';
import Button from '../components/Button';

import LanguageSelector from '../components/LanguageSelector';
import { getLanguage } from '../utils/getLanguage';

import * as cookie from '../utils/cookieManager';

import { REDIRECTION_TO_PAGE_PARAM } from '../constants';


const propTypes = {
  emailHint: PropTypes.string,
  extraHeaders: PropTypes.object,
  noCreateAccountLink: PropTypes.bool
}

export default function SignedOut({extraHeaders = null}) {
  const [tr, i18n] = useTranslation();
  const language = getLanguage();

  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);
  const [state, setState] = useState({nextRedirection: null});
  const signInExtraHeaders = extraHeaders || {};
  const currentPageLocation = useLocation();

  if (auth.cocoomAccountUri) {
    signInExtraHeaders['x-cocoom-uri'] = auth.cocoomAccountUri;
  }

  useEffect(() => {
    cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
    cookie.resetCookie(cookie.SIGNEDIN_ACCOUNT_REDIRECTION_COOKIE_NAME);

    if (currentPageLocation.search.indexOf(REDIRECTION_TO_PAGE_PARAM) > 0) {
      const urlParams = queryString.parse(currentPageLocation.search);
      setState({nextRedirection: urlParams[REDIRECTION_TO_PAGE_PARAM]});
    }
  }, [currentPageLocation.search]);

  function changeLanguage(language) {
    i18n.changeLanguage(language);
  };

  return (
    <React.Fragment>
      <CenteredPanelPage
        backgroundColor={customization.backgroundColor}
        backgroundImage={customization.backgroundImage}
        headerBar={(
          <div style={{textAlign: 'right'}}>
            <LanguageSelector language={language} onChange={changeLanguage}/>
          </div>
        )}
        topLogo={customization.topLogo}
        topLogoAlt={customization.topLogoAlt}
        topLogoInPanel={customization.topLogoInPanel}
        subtitle={tr('signedout.message')}
      >
        <div style={{textAlign: 'center'}}>
          { /* We must use an absolute path URL because some server side execution need to be done; it can't be a client-side relative hyperlink (such as  <Link>) */}
          <Button
            label={tr('signedout.signinSameAccount')}
            disabled={!state.nextRedirection}
            onClick={() => window.location.replace('/signin' + (state.nextRedirection ? '?next=' + state.nextRedirection : ''))}
          />

          <Button
            color="default"
            label={tr('signedout.signinAnotherAccount')}
            onClick={() => window.location.replace('/signin')}
          />
        </div>
      </CenteredPanelPage>
    </React.Fragment>
  );
}

SignedOut.propTypes = propTypes;
