import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import './index.css';
import './i18n';

import {
  setCocoomAccountUri,
  setCocoomAuthInfo
} from './reducers/auth';
import {
  setCustomization,
  setCDNEndpointUri
} from './reducers/customization';

import reducer from './reducers';


//// INIT
function init() {
  const DEFAULT_CDN_ENDPOINT_URI = 'https://static.cocoom.com';

  const store = createStore(reducer, applyMiddleware(thunk));

  try {
    const metaTagNode = document.querySelector('meta[property=data]');
    if (metaTagNode) {
      const metaTagEncodedContent = metaTagNode.getAttribute('content');
      if (metaTagEncodedContent && metaTagEncodedContent !== '') {
        try {
          const parsedData = JSON.parse(decodeURIComponent(escape(atob(metaTagEncodedContent))));
          if (parsedData.cocoomAccountUri) {
            store.dispatch(setCocoomAccountUri(parsedData.cocoomAccountUri));
          }
          store.dispatch(setCDNEndpointUri(parsedData.cdnEndpointUrl ? parsedData.cdnEndpointUrl : DEFAULT_CDN_ENDPOINT_URI));
          if (parsedData.auth) {
            store.dispatch(setCocoomAuthInfo(parsedData.auth));
          }

          store.dispatch(setCustomization(parsedData.customization, parsedData.cdnEndpointUrl));

          if (window) {
            window.INTERCOM_APP_ID = parsedData ? parsedData.IntercomId : null;
          }
        } catch (decodeError) {
          // Ignore the error, no encoded customization found so tell it to the App with default CDN value
          store.dispatch(setCustomization(null, DEFAULT_CDN_ENDPOINT_URI));
          store.dispatch(setCDNEndpointUri(DEFAULT_CDN_ENDPOINT_URI));
        }
      }
    }
  } catch(error) {
    console.error('Unexpected data from back-end: ', error);
  }

  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback="Loading...">
        <App />
      </Suspense>
    </Provider>, document.getElementById('root')
  );
}

if (process.env.NODE_ENV === "development") {
  // DEV initialization workflow
  console.log('[ MirageJS ] Launching API mocking server...');
  import('./mocking/apiMockingServer').then((m) => {
    m.makeApiMockingServer();
    console.log('[ MirageJS ] Launched.');

    const dataMeta = document.querySelector('meta[property=data]');
    dataMeta.setAttribute('content', btoa(unescape(encodeURIComponent(JSON.stringify(require('./mocking/customization'))))));

    init();
  });

} else {
  // PROD initialization workflow
  init();
}
