let currentKey = null;
export default function getCaptchaKey() {
  const hostname = document.location.hostname;
  if (!currentKey) {
    if (hostname.indexOf('qa.cocoom.com') > -1) {
      currentKey = '6LeclcgUAAAAAHbmM0ovoaDoHuJjFESoHRRbb-Rb';
    } else if (hostname.indexOf('staging.cocoom.com') > -1) {
      currentKey = '6LcelsgUAAAAANnDMucQaVrAm8qN7ho2uVo1R_4q';
    } else if (hostname.indexOf('cocoom.com') > -1) {
      currentKey = '6LdDIsUUAAAAAFWNdsHp7jKzk0pFQUX7HEMWasOO';
    } else if (hostname.indexOf('localhost') > -1) {
      currentKey = '6LfQI8UUAAAAAPQFVnkcq3wwXLUoIE-FnH52FJFU';
    }
  }

  return currentKey;
}
