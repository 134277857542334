import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  fr: {
    translation: require('./i18n/fr.json')
  },
  en: {
    translation: require('./i18n/en.json')
  }
};

export function getDefaultLanguage() {
  if (window.location.search.indexOf('lng=fr') >= 0) {
    return 'fr';
  }
  if (window.location.search.indexOf('lng=en') >= 0) {
    return 'en';
  }
  const i18LSValue = localStorage.getItem('i18nextLng');
  if (i18LSValue) {
    return i18LSValue.substring(0, 2);
  }

  return navigator.language.substring(0, 2);
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next )
  .init({
    resources,
    lng: getDefaultLanguage(), // uncomment this line to force a initial language setting
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // escapeValue is not needed for react as it escapes by default
    },
    debug: (process.env.NODE_ENV === 'production') ? false : true,
    react: {
      useSuspense: true
    }
  });

export default i18n;
