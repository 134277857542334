import React from 'react';

import { useLocation } from "react-router-dom";


export default function BaseRoutedContainer({children}) {
  const currentPageLocation = useLocation();

  React.useEffect(() => {
    if (window && window.Intercom && currentPageLocation) {
      window.Intercom('update', { current_location: currentPageLocation.pathname, time: new Date().getTime() });
    }
  }, [currentPageLocation]);

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}
