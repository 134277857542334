import React from 'react';

import Facebook from './icons/social/Facebook';
import Twitter from './icons/social/Twitter';
import LinkedIn from './icons/social/LinkedIn';
import Youtube from './icons/social/Youtube';
import Instagram from './icons/social/Instagram';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
import { formFieldStyles } from './styles';

const useStyles = makeStyles(theme => ({
  pageFooter: {
    borderStyle: 'solid',
    borderWidth: '1px 0px 0px 0px',
    borderColor: '#e9ecf3',
    padding: theme.spacing(4, 0),
    textAlign: 'center',
    backgroundColor: '#fff'
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://cocoom.com" target="_blank">
        Cocoom SAS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function PageFooter() {
  const classes = useStyles();
  const styles = formFieldStyles();
  return (
    <div className={classes.pageFooter}>
      <div>
        <IconButton aria-label="Facebook" className={styles.iconButton} href="https://www.facebook.com/CocoomHQ" target="_blank">
          <Facebook />
        </IconButton>
        <IconButton aria-label="Twitter" className={styles.iconButton} href="https://twitter.com/cocoomhq" target="_blank">
          <Twitter />
        </IconButton>
        <IconButton aria-label="LinkedIn" className={styles.iconButton} href="https://www.linkedin.com/company/10979820" target="_blank">
          <LinkedIn />
        </IconButton>
        <IconButton aria-label="Youtube" className={styles.iconButton} href="https://www.youtube.com/channel/UCERFg66BzwsXrVdQcwYw85A" target="_blank">
          <Youtube />
        </IconButton>
        <IconButton aria-label="Instagram" className={styles.iconButton} href="https://www.instagram.com/cocoom_hq" target="_blank">
          <Instagram />
        </IconButton>
      </div>
      <Copyright />
    </div>
  );
}
