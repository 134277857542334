import superagent from 'superagent';
import * as validate from './validators/registerForm';
import { getDefaultLanguage } from '../i18n';

/*
 * actions
 */
const SET_TITLE_TYPE = '/register/SET_TITLE_TYPE';
const SET_DOMAIN_TYPE = '/register/SET_DOMAIN_TYPE';
const SET_USAGE_TYPE = '/register/SET_USAGE_TYPE';
const SET_LASTNAME_TYPE = '/register/SET_LASTNAME_TYPE';
const SET_FIRSTNAME_TYPE = '/register/SET_FIRSTNAME_TYPE';
const SET_EMAIL_TYPE = '/register/SET_EMAIL_TYPE';
const SET_PASSWORD_TYPE = '/register/SET_PASSWORD_TYPE';
const SET_PASSWORD_CONFIRMATION_TYPE = '/register/SET_PASSWORD_CONFIRMATION_TYPE';
const SET_PHONE_NUMBER_TYPE = '/register/SET_PHONE_NUMBER_TYPE';
const SET_NEWSLETTER_TYPE = '/register/SET_NEWSLETTER_TYPE';
const SET_TERMS_TYPE = '/register/SET_TERMS_TYPE';
const SET_LANGUAGE_TYPE = '/register/SET_LANGUAGE_TYPE';
const SET_AZURE_TENANT_ID = '/register/SET_AZURE_TENANT_ID';
const SET_PRICING = '/register/SET_PRICING';
const SET_PASSWORD_STRENGTH_FEEDBACK = '/register/SET_PASSWORD_STRENGTH_FEEDBACK';

const DISMISS_ACTION_ERROR = '/register/DISMISS_ACTION_ERROR';

const CHECK_DOMAIN_AVAILABILITY_PENDING = '/register/CHECK_DOMAIN_AVAILABILITY_PENDING';
const CHECK_DOMAIN_AVAILABILITY_DONE = '/register/CHECK_DOMAIN_AVAILABILITY_DONE';
const CHECK_DOMAIN_AVAILABILITY_FAILED = '/register/CHECK_DOMAIN_AVAILABILITY_FAILED';

const CREATE_PLATFORM_PENDING = '/register/CREATE_PLATFORM_PENDING';
const CREATE_PLATFORM_DONE = '/register/CREATE_PLATFORM_DONE';
const CREATE_PLATFORM_FAILED = '/register/CREATE_PLATFORM_FAILED';

const VALIDATE_STEP_TYPE = '/register/VALIDATE_STEP_TYPE';
const GET_BACK_TO_STEP_TYPE = '/register/GET_BACK_TO_STEP_TYPE';

export const CHECK_DOMAIN_ACTION = '/register/CHECK_DOMAIN_ACTION';
export const CREATE_DOMAIN_ACTION = '/register/CREATE_DOMAIN_ACTION';


/*
 * action creators
 */
export function setTitle(title) {
  return {type: SET_TITLE_TYPE, title: title};
}

function checkDomainAvailablity(domain) {
  return async function (dispatch) {
    dispatch({type: CHECK_DOMAIN_AVAILABILITY_PENDING});
    try {
      const result = await superagent.get('/api/start/account/checkdomain').query({domain: domain});
      if (!result.body || result.body.domainAvailable === undefined) {
        throw new Error('unexpected response payload');
      }
      dispatch({type: CHECK_DOMAIN_AVAILABILITY_DONE, domainAvailable: Boolean(result.body.domainAvailable)});
    } catch (error) {
      if (error.response && error.response.body) {
        // console.log('ERROR RAISED : ' + JSON.stringify(error.response.body, null, 4));
        dispatch({type: CHECK_DOMAIN_AVAILABILITY_FAILED, error: error.response.body});
      } else {
        dispatch({type: CHECK_DOMAIN_AVAILABILITY_FAILED, error: {code: '---', message: error.message}});
      }
    }
  }
}

let checkDomainTimeout = null;
export function setDomain(domain) {
  return async function (dispatch) {
    clearTimeout(checkDomainTimeout);
    dispatch({type: SET_DOMAIN_TYPE, domain: domain});
    if (validate.validateDomain(domain, null) === null) {
      checkDomainTimeout = setTimeout(() => {
        dispatch(checkDomainAvailablity(domain));
      }, 1200);
    }
  }
}

export function setUsage(usage) {
  return {type: SET_USAGE_TYPE, usage: usage};
}

export function setLastname(lastname) {
  return {type: SET_LASTNAME_TYPE, lastname: lastname};
}

export function setFirstname(firstname) {
  return {type: SET_FIRSTNAME_TYPE, firstname: firstname};
}

export function setEmail(email) {
  return {type: SET_EMAIL_TYPE, email: email};
}

export function setPassword(password) {
  return {type: SET_PASSWORD_TYPE, password: password};
}

export function setPasswordConfirmation(passwordConfirmation) {
  return {type: SET_PASSWORD_CONFIRMATION_TYPE, passwordConfirmation: passwordConfirmation};
}

export function setPhoneNumber(phoneNumber) {
  return {type: SET_PHONE_NUMBER_TYPE, phoneNumber: phoneNumber};
}

export function setNewsletter(newsletter) {
  return {type: SET_NEWSLETTER_TYPE, newsletter: newsletter};
}

export function setTerms(terms) {
  return {type: SET_TERMS_TYPE, terms: terms};
}

export function setLanguage(language) {
  return {type: SET_LANGUAGE_TYPE, language: language};
}

export function setAzureTenantId(azureTenantId) {
  return {type: SET_AZURE_TENANT_ID, azureTenantId: azureTenantId};
}

export function setPricing(pricing) {
  return {type: SET_PRICING, pricing: pricing};
}

export function setPasswordStrengthFeedback(acceptable, message) {
  // console.log('function setPasswordStrengthFeedback', {type: SET_PASSWORD_STRENGTH_FEEDBACK, acceptable: acceptable, message: message});
  return {type: SET_PASSWORD_STRENGTH_FEEDBACK, acceptable: acceptable, message: message};
}

export function dismissActionError() {
  return {type: DISMISS_ACTION_ERROR};
}

function createPlatform(state) {
  return async function (dispatch) {
    dispatch({type: CREATE_PLATFORM_PENDING});
    try {
      const result = await superagent.post('/api/start/account/create').send({
        user: {
          email: state.email.value,
          password: state.password.value,
          firstName: state.firstname.value,
          lastName: state.lastname.value,
          phone: state.phoneNumber.value,
          acceptBePartOfNewsletter: state.newsletter
        },
        platform: {
          domain: state.domain.value,
          name: state.title.value,
          usage: state.usage.value,
          language: state.language,
          aadTenantId: state.azureTenantId,
          pricing: state.pricing
        }
      });

      if (!result.body || result.body.name === undefined || result.body.email === undefined || result.body.url === undefined) {
        throw new Error('unexpected response payload');
      }
      dispatch({type: CREATE_PLATFORM_DONE, platform: {
        title: result.body.name,
        email: result.body.email,
        url: result.body.url,
      }});
    } catch (error) {
      if (error.response) {
        dispatch({type: CREATE_PLATFORM_FAILED, error: error.response});
      } else {
        dispatch({type: CREATE_PLATFORM_FAILED, error: {statusCode: '---', text: error.message}});
      }
    }
  }
}

export function validateStep(step, {noPassword} = {}) {
  return function (dispatch, getState) {
    dispatch({type: VALIDATE_STEP_TYPE, step, noPassword});
    if (step === 2) {
      const { register } = getState();
      if (register.currentStep === 3) {
        dispatch(createPlatform(register));
      }
    }
  }
}

export function getBackToStep(step) {
  if (step < 0 || step > 2) {
    throw new Error('step must be a number between 0 and 2 included, but worth ' + step);
  }

  return {type: GET_BACK_TO_STEP_TYPE, step: step};
}



/*
 * initial state
 */
const initialState = {
  title: {value: '', error: ''},
  domain: {value: '', error: '', available: null},
  usage: {value: '', error: ''},
  lastname: {value: '', error: ''},
  firstname: {value: '', error: ''},
  email: {value: '', error: ''},
  password: {value: '', helper: '', error: ''},
  passwordConfirmation: {value: '', error: ''},
  phoneNumber: {value: '', error: ''},
  language: getDefaultLanguage(),
  newsletter: false,
  terms: {value: false, error: ''},
  azureTenantId: undefined,
  pricing: 'free',
  currentStep: 0,
  action: null,
  actionState: 'idle',
  actionError: null,
  createdPlatform: null
}

/*
 * reducer
 */
export default function reducer(state = initialState, action){
  // console.log('REDUCER', state.language, action);
  switch (action.type) {
      case SET_TITLE_TYPE:
        return {
          ...state,
          title: {
            value: action.title,
            error: validate.validateTitle(action.title)
          }
        };

      case SET_DOMAIN_TYPE: {
        const domain = action.domain.toLowerCase();
        return {
          ...state,
          domain: {
            value: domain,
            error: validate.validateDomain(domain, state.domain.available)
          }
        };
      }

      case SET_USAGE_TYPE:
        return {
          ...state,
          usage: {
            value: action.usage,
            error: validate.validateUsage(action.usage)
          }
        };

      case SET_LASTNAME_TYPE:
        return {
          ...state,
          lastname: {
            value: action.lastname,
            error: validate.validateName(action.lastname)
          }
        };

      case SET_FIRSTNAME_TYPE:
        return {
          ...state,
          firstname: {
            value: action.firstname,
            error: validate.validateName(action.firstname)
          }
        };

      case SET_EMAIL_TYPE:
        return {
          ...state,
          email: {
            value: action.email,
            error: validate.validateEmail(action.email)
          }
        };

      case SET_PASSWORD_TYPE:
        return {
          ...state,
          password: {
            value: action.password,
            helper: state.password.helper,
            error: state.password.error
          },
          passwordConfirmation: {
            value: state.passwordConfirmation.value,
            error: state.passwordConfirmation.value && validate.validatePasswordConfirmation(action.password, state.passwordConfirmation.value)
          }
        };

      case SET_PASSWORD_CONFIRMATION_TYPE:
        return {
          ...state,
          passwordConfirmation: {
            value: action.passwordConfirmation,
            error: validate.validatePasswordConfirmation(state.password.value, action.passwordConfirmation)
          }
        };

      case SET_PASSWORD_STRENGTH_FEEDBACK:
        // console.log(SET_PASSWORD_STRENGTH_FEEDBACK, action);
        return {
          ...state,
          password: {
            value: state.password.value,
            helper: action.acceptable ? action.message : null,
            error: !action.acceptable ? action.message : null
          }
        }

      case SET_PHONE_NUMBER_TYPE:
        return {
          ...state,
          phoneNumber: {
            value: action.phoneNumber,
            error: validate.validatePhoneNumber(action.phoneNumber)
          }
        };

      case SET_NEWSLETTER_TYPE:
        return {
          ...state,
          newsletter: action.newsletter
        };

      case SET_TERMS_TYPE:
        return {
          ...state,
          terms: {
            value: action.terms,
            error: validate.validateTerms(action.terms)
          }
        };

      case SET_LANGUAGE_TYPE:
        return {
          ...state,
          language: action.language
        };

      case SET_AZURE_TENANT_ID:
        return {
          ...state,
          azureTenantId: action.azureTenantId
        };

      case SET_PRICING:
        return {
          ...state,
          pricing: action.pricing
        };

      case DISMISS_ACTION_ERROR:
        return {
          ...state,
          actionState: 'idle',
          actionError: null
        }

      case CHECK_DOMAIN_AVAILABILITY_PENDING:
        return {
          ...state,
          domain: {
            ...state.domain,
            available: null,
            error: validate.validateDomain(state.domain.value, null)
          },
          action: CHECK_DOMAIN_ACTION,
          actionState: 'working'
        };

      case CHECK_DOMAIN_AVAILABILITY_DONE:
        return {
          ...state,
          domain: {
            ...state.domain,
            available: action.domainAvailable,
            error: validate.validateDomain(state.domain.value, action.domainAvailable)
          },
          actionState: 'done'
        };

      case CHECK_DOMAIN_AVAILABILITY_FAILED:
        return {
          ...state,
          domain: {
            ...state.domain,
            available: null
          },
          actionState: 'failed',
          actionError: {statusCode: action.error.code, message: action.error.message}
        };

      case VALIDATE_STEP_TYPE:
        switch (action.step) {
          case 0: {
            const newState = {...state};
            newState.title.error =  validate.validateTitle(state.title.value);
            newState.domain.error =  validate.validateDomain(state.domain.value, state.domain.available);
            newState.usage.error =  validate.validateUsage(state.usage.value);

            if(!newState.title.error && !newState.domain.error && !newState.usage.error) {
              if (newState.domain.available && newState.actionState === 'done') {
                newState.currentStep = 1;
              }
            };
            return newState;
          }

          case 1: {
            const newState = {...state};
            // console.log('validation', newState);
            newState.lastname.error =  validate.validateName(state.lastname.value);
            newState.firstname.error =  validate.validateName(state.firstname.value);
            newState.email.error =  validate.validateEmail(state.email.value);
            if (!action.noPassword) {
              newState.password.error =  state.password.error;
              newState.password.helper =  state.password.helper;
              newState.passwordConfirmation.error =  validate.validatePasswordConfirmation(state.password.value, state.passwordConfirmation.value);
            }
            if(!newState.lastname.error && !newState.firstname.error && !newState.email.error && !newState.password.error && !newState.passwordConfirmation.error) {
              newState.currentStep = 2;
            };
            return newState;
          }

          case 2: {
            const newState = {...state};
            // newState.phoneNumber.error =  validate.validatePhoneNumber(state.phoneNumber.value);
            newState.terms.error =  validate.validateTerms(state.terms.value);
            if(!newState.phoneNumber.error && !newState.terms.error) {
              newState.currentStep = 3;
            };
            return newState;
          }

          default:
            return state;
        }

      case GET_BACK_TO_STEP_TYPE:
        return {
          ...state,
          currentStep: action.step
        }

      case CREATE_PLATFORM_PENDING:
        return {
          ...state,
          action: CREATE_DOMAIN_ACTION,
          actionState: 'working'
        };

      case CREATE_PLATFORM_DONE:
        return {
          ...state,
          createdPlatform: action.platform,
          actionState: 'done'
        };

      case CREATE_PLATFORM_FAILED:
        return {
          ...state,
          actionState: 'failed',
          actionError: {statusCode: action.error.statusCode, message: action.error.text},
          currentStep: 2
        };

      default:
       return state;
    }
  }
