import { makeStyles } from '@material-ui/core/styles';

export const cocoomPrimaryColor = {
  main: '#0056ec',
  light: '#3377ef',
  dark: '#2e3192'
};

export const cocoomSuccessColor = '#4caf50';
export const cocoomErrorColor = '#f44336';

const linkStyles = theme => ({
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.dark
  },
  '&:visited': {
    color: theme.palette.primary.dark
  },
  '&.disabled': {
    color: theme.palette.text.disabled
  }
});

export const formFieldStyles = (options = {}) => makeStyles(theme => {
  const formLinkStyles = linkStyles(theme);
  formLinkStyles.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
  formLinkStyles.fontSize = '0.75rem';

  return {
  checkbox: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1,3),
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      '& .MuiFormControlLabel-label': {
        fontSize: options.checkboxLabelFontSize,
        lineHeight: '1.5rem',
        paddingTop: theme.spacing(1)
      },
      '& .MuiIconButton-root': {
        marginLeft: theme.spacing(-1)
      }
    }
  },
  checkboxIcon: {
    color: theme.palette.primary.main
  },
  field: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2),
    width: options.width,
    '& MuiInputBase-root': {
      boxSizing: 'border-box',
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.light
    },
    '& .MuiFormLabel-root': {
      marginLeft: theme.spacing(2),
      color: theme.palette.primary.main
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.text.disabled
    },
    '& .MuiFormLabel-root.Mui-error' : {
      color: cocoomErrorColor
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '&:hover fieldset': {
        borderColor: cocoomErrorColor
      }
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      '&:hover fieldset': {
        borderColor: theme.palette.action.disabled
      }
    },
    '& .MuiFormHelperText-root': {
      color: options.valid ? cocoomSuccessColor : undefined,
      fontWeight: options.valid ? 'bold' : undefined
    }
  },
  iconButton: {
    margin: theme.spacing(1,1),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  link: formLinkStyles
};})();
