import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';

import Page from './Page';
import PageRow from './PageRow';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = options => makeStyles(theme => ({
  '@keyframes global-anim': {
   ' 0%': {
      transform: 'rotateZ(0deg)'
    },
    '100%': {
      transform: 'rotateZ(360deg)'
    }
  },
  '@keyframes circle-anim': {
    '0%': {
      strokeDashoffset: options.circ - (options.circ * .05),
      transform: 'rotate(0)'
    },
    '50%': {
      strokeDashoffset: options.circ - (options.circ * .75),
      transform: 'rotate(45deg)'
    },
    '100%': {
      strokeDashoffset: options.circ - (options.circ * .05),
      transform: 'rotate(360deg)'
    },
  },
  svg: {
    width: options.size,
    height: options.size,
    animation: '2s linear infinite $global-anim',
   '& circle': {
    fill: 'transparent',
    stroke: options.color,
    strokeWidth: options.thickness,
    strokeDasharray: options.circ,
    strokeLinecap: 'round',
    animation: '1.4s ease-in-out infinite both $circle-anim',
    transformOrigin: '50% 50%'
   }
  }
}))();

const propTypes = {
  color: PropTypes.string,
  fullPage: PropTypes.bool,
  size: PropTypes.number,
  thickness: PropTypes.number
};

export default function CircularProgress({color = '#2f3d4c', fullPage = false, size = 40, thickness = 3.6}) {
  const radius = (size / 2) - thickness;
  const classes = useStyles({
    size: size,
    circ: 2 * 3.1415927 * radius,
    thickness: thickness,
    color: color
  });

  function getPage(content) {
    return (
      <Page maxWidth="sm" fullHeight centeredContent>
        <PageRow nbColumnsXs={1} centered>
          {content}
        </PageRow>
      </Page>
    );
  }

  const loader = (
    <SvgIcon viewBox={`0 0 ${size} ${size}`} className={classes.svg}>
      <circle cx={size / 2} cy={size / 2} r={radius}/>
    </SvgIcon>
  );

  if (fullPage) {
    return getPage(loader);
  }

  return loader;
}

CircularProgress.propTypes = propTypes;
