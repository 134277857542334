import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  childrenType
} from '../propTypes';

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';

import ApiErrorAlert from '../components/ApiErrorAlert';
import RedirectionManager from './RedirectionManager';

import {
  CHECK_USER_AUTH_ACTION_ID,
  GET_OIDC_AUTHORIZATION_URL_ACTION_ID,
  SIGNIN_ACTION_ID,
  // SIGNOUT_ACTION_ID,
  checkUserAuthentification,
  redirectToAccountUrl,
  redirectToOIDCIdpAuthRequestUrl
} from '../reducers/auth';


const propTypes = {
  children: childrenType,
  noRedirection: PropTypes.bool,
  redirectIfAuthentified: PropTypes.string
}


export default function UserAuthenticationChecker({children, noRedirection = false, redirectIfAuthentified = undefined}) {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMesssage, setErrorMessage] = useState(null);

  const checkingAuth = auth.action &&  !auth.userChecked && auth.actionState !== 'idle' && ((auth.action === CHECK_USER_AUTH_ACTION_ID  && auth.actionState === 'working') || auth.action === GET_OIDC_AUTHORIZATION_URL_ACTION_ID || auth.oidcAuthorizationUrl);

  useEffect(() => {
    if (!auth.userChecked) {
      // alert('Checking auth...');
      // console.log(JSON.stringify(auth, null, 4));
      // console.log('user = ' + JSON.stringify(auth.user, null, 2));
      let promptForCredentials = false;
      if (window.location.search.indexOf('prompt') > -1) {
        promptForCredentials = true;
      }

      dispatch(checkUserAuthentification(promptForCredentials));
    }
  }, [dispatch, auth.userChecked]);

  useEffect(() => {
    // console.log('UserAuthenticationChecker >> auth.action = ' + auth.action);
    if (redirectIfAuthentified && auth.action === CHECK_USER_AUTH_ACTION_ID && auth.actionState === 'done') {
      // alert('A. UserAuthenticationChecker >> navigate to ' + redirectIfAuthentified + ' | User auth is checked');
      history.push(redirectIfAuthentified);
      return;
    }
  }, [history, auth.action, auth.actionState, redirectIfAuthentified]);

  useEffect(() => {
    if (auth.action === CHECK_USER_AUTH_ACTION_ID && auth.actionState === 'done' && auth.user && auth.user.accounts && auth.user.accounts.length === 1) {
      // console.log('B. UserAuthenticationChecker >> redirect to ' + auth.user.accounts[0].url + ' | User auth is checked');
      dispatch(redirectToAccountUrl(auth.user.accounts[0].url));
    }
  }, [dispatch, auth.action, auth.actionState, auth.user]);

  useEffect(() => {
    if (auth.action === CHECK_USER_AUTH_ACTION_ID && auth.actionState === 'failed' && auth.actionError.statusCode !== 401) {
      setErrorMessage(<ApiErrorAlert statusCode={auth.actionError.statusCode} message={auth.actionError.text} body={auth.actionError.body}/>);
    }
  }, [auth.action, auth.actionState, auth.actionError]);

  useEffect(() => {
    if (auth.oidcIdPAuthRequestUrl) {
      // alert('UserAuthenticationChecker >> redirect (replace) to oidcIdPAuthRequestUrl: ' + auth.oidcIdPAuthRequestUrl);
      window.location.replace(auth.oidcIdPAuthRequestUrl);
    }
  }, [auth.oidcIdPAuthRequestUrl]);

  useEffect(() => {
    if (auth.authMode === 'oidc' && auth.actionState === 'done' && auth.oidcAuthorizationUrl) {
      // alert('auth.actionState === done && auth.oidcAuthorizationUrl > Redirect to URL : ' + auth.oidcAuthorizationUrl);
      dispatch(redirectToOIDCIdpAuthRequestUrl(auth.oidcAuthorizationUrl));
    }
  }, [dispatch, auth.authMode, auth.action, auth.actionState, auth.oidcAuthorizationUrl]);

  useEffect(() => {
    if (auth.action === SIGNIN_ACTION_ID && auth.actionState === 'done' && auth.user && auth.user.accounts && auth.user.accounts.length === 1) {
      // alert('UserAuthenticationChecker >> Redirect to account URL : ' + auth.user.accounts[0].url);
      dispatch(redirectToAccountUrl(auth.user.accounts[0].url));
    }
  }, [dispatch, auth.action, auth.actionState, auth.user]);


  // console.log('-------> noRedirection = ' + noRedirection + ' | checkingAuth = ' + checkingAuth);

  if (noRedirection) {
    // console.log('1. UserAuthenticationChecker >> errorMesssage ? ' + errorMesssage + ' otherwise display its children');
    return (<>{errorMesssage || children}</>);
  }

  return (
    <RedirectionManager forceLoadingView={checkingAuth}>
      {(errorMesssage || children)}
    </RedirectionManager>
  )
}

UserAuthenticationChecker.propTypes = propTypes;
