import React from 'react';
import PropTypes from 'prop-types';
import {
  childrenType
} from '../propTypes';

import useWidth from '../hooks/useWidth';

import MUIPaper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    root: {
      position: 'relative',
      backgroundColor: '#fff',
      textAlign: 'left',
      padding: theme.spacing(4,4)
    },
    rootXs: {
      position: 'relative',
      backgroundColor: '#fff',
      textAlign: 'left',
      padding: theme.spacing(1,1,4,1),
      height: '100%'
    }
  }
});

const propTypes = {
  children: childrenType,
  elevation: PropTypes.number
}

export default function Paper({ children, elevation = 2 }) {
  const classes = useStyles();
  const gridWidth = useWidth();

  return (
    <MUIPaper elevation={elevation} className={(gridWidth === 'xs') ? classes.rootXs : classes.root}>
      {children}
    </MUIPaper>
  );
}

Paper.propTypes = propTypes;
