import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: props => ({
    minHeight: props.fullHeight ? '100%' : undefined,
    backgroundColor: props.backgroundColor,
    backgroundImage: props.backgroundImage,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    alignItems: props.centeredContent ? 'center' : undefined,
    justifyContent: props.centeredContent ? 'center' : undefined,
    display: props.centeredContent ? 'flex' : undefined,
    padding: props.fullWidth ? 0 : undefined
  })
}));

const propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  centeredContent: PropTypes.bool,
  fullHeight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  fullWidth: PropTypes.bool
};

export default function Page({
  backgroundColor = undefined,
  backgroundImage = 'linear-gradient(0deg, #edf3fc 32%, #ffffff 58%)',
  centeredContent = false,
  children = <h1>a page content</h1>,
  fullHeight = false,
  maxWidth = false,
  fullWidth = false
}) {
  const classes = useStyles({fullHeight, centeredContent, backgroundColor, backgroundImage, fullWidth });
  return (
    <Container maxWidth={false} component="main" className={classes.root}>
      <Container maxWidth={maxWidth}>
        {children}
      </Container>
    </Container>
  );
}

Page.propTypes = propTypes;
