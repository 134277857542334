import { combineReducers } from 'redux';
import auth from './auth';
import customization from './customization';
import register from './register';

export default combineReducers({
  auth,
  customization,
  register
})
