import React from 'react';
import PropTypes from 'prop-types';
import {
  childrenType
} from '../propTypes';

import { Link as ReactRouterLink } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link:{
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark
    },
    '&:visited': {
      color: theme.palette.primary.dark
    }
  },
  disabledLink: {
    color: theme.palette.text.disabled
  }
}));

const propTypes = {
  disabled: PropTypes.bool,
  formStyle: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: childrenType
}

export default function Link({ disabled = false, to, children }) {

  const classes = useStyles();

  if (disabled) {
    return <span className={classes.disabledLink}>{children}</span>
  }

  return (
    <ReactRouterLink to={to} className={classes.link}>{children}</ReactRouterLink>
  );
}

Link.propTypes = propTypes;
