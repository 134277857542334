import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    sectionContainer: {
      position: 'relative',
      width: '100%',
      transition: 'left 300ms ease-in-out'
    },
    section: {
      display: 'inline-block',
      verticalAlign: 'top'
    }
  };
});

const propTypes = {
  currentSectionIndex: PropTypes.number,
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  width: PropTypes.number,
  onChange: PropTypes.func
};

export default function HorizontalSlidingSections({children, width, fullWidth = false, currentSectionIndex = 0, onChange = () => {}}) {
  if (!fullWidth && !width) {
    throw new Error('if "fullWidth" is not provided, "width" must be greater than 0. Here it worth ' + width);
  }

  const nbOfChildren = React.Children.count(children);
  const unit = fullWidth ? '%' : 0;
  const containerWidth = fullWidth ? (100 * nbOfChildren) : (width * nbOfChildren);
  const sectionWidth = fullWidth ? (100 / nbOfChildren) : width;
  const classes = useStyles();
  const previousSectionIndexRef = useRef(currentSectionIndex);

  useEffect(() => {
    previousSectionIndexRef.current = currentSectionIndex;
    onChange(currentSectionIndex);
  }, [currentSectionIndex, onChange]);

  return (<div style={{width: containerWidth + unit}}><div className={classes.sectionContainer} style={{left: (-1 * currentSectionIndex * sectionWidth) + unit}}>
    {React.Children.map(children, (child, index) => {
      return (
        <div className={classes.section} style={{width: sectionWidth + unit}}>
          {(currentSectionIndex === index || previousSectionIndexRef.current === index) ? child : <span>&nbsp;</span>}
        </div>
      );
    })}
  </div></div>)
}

HorizontalSlidingSections.propTypes = propTypes;
