import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import { ReCaptcha } from 'react-recaptcha-v3';

import {validateEmail} from '../reducers/validators/registerForm';

import AlignableImage from '../components/AlignableImage';
import ApiErrorAlert from '../components/ApiErrorAlert';
import Button from '../components/Button';
import CenteredPanelPage from '../components/CenteredPanelPage';
import LanguageSelector from '../components/LanguageSelector';
import Select from '../components/Select';
import TextField from '../components/TextField';
import Typography from '../components/Typography';

import {
  ACCESS_REQUEST_ACTION_ID,
  accessRequest,
  resetActionState
} from '../reducers/auth';

import { getMsTeamsContext, getMsTeamsOrStandalonePath } from '../utils/getMsTeamsContext';
import getCaptchaKey from '../utils/getCaptchaKey';
import { getLanguage } from '../utils/getLanguage';
import * as cookie from '../utils/cookieManager';


export default function AccessRequest() {
  const [tr, i18n] = useTranslation();
  const language = getLanguage();
  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);
  const [msTeamsContext, setMsTeamsContext] = useState(null);

  const working = auth.actionState === 'working';

  const currentStep = (auth.action === ACCESS_REQUEST_ACTION_ID) ? auth.actionState : 'form';
  const uiState = {
    displayGroup: customization.accessRequest.group && customization.accessRequest.group.items && (customization.accessRequest.group.items.length > 0)
  }
  const dispatch = useDispatch();
  const [state, setState] = useState({firstname: '', lastname: '', group: uiState.displayGroup ? customization.accessRequest.group.items[0][language]: ''});
  const [emailState, setEmailState] = useState({email: '', emailError: null});

  cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);


  useEffect(() => {
    const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);

    getMsTeamsContext({encodedData: msTeamsOIDCSigninCookie, onContext: (context) => {
      setEmailState({email: context.loginHint});
      setMsTeamsContext(context);
    }});
  }, []);


  useEffect(() => {
    dispatch(resetActionState());
  }, [dispatch]);


  function changeLanguage(language) {
    i18n.changeLanguage(language);
  }

  function getConfirmationView() {
    return (
      <React.Fragment>
        <AlignableImage align="center" src="/send.svg" alt="Message sent" imgStyle={{width: 100, height: 100}}/>
        {<Typography align="center" altFont>{tr('accessRequest.mailSentMessage')}</Typography>}
      </React.Fragment>
    );
  }

  function getErrorAlert() {
    const statusCode = auth.actionError.statusCode;

    if (auth.action === ACCESS_REQUEST_ACTION_ID) {
      if (statusCode === 400) {
        return (
          <React.Fragment>
            <ApiErrorAlert message={tr('accessRequest.error.badRequest')} body={tr('accessRequest.error.badRequestHint')}/>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <ApiErrorAlert statusCode={statusCode} message={auth.actionError.text} body={auth.actionError.body}/>
        </React.Fragment>
      );
    }

    return null;
  }

  function validateThenSetUserEmail(emailValue) {
    const emailError = validateEmail(emailValue);
    setEmailState({email: emailValue, emailError: emailError});
  }

  function getRequestAccessForm() {
    return (
      <React.Fragment>
        <TextField
          id="firstname"
          label={tr('accessRequest.form.firstname.label')}
          autocomplete="given-name"
          errorText={!state.firstname ? tr('accessRequest.error.missingFirstName') : null}
          onChange={event => setState({...state, firstname: event.target.value})}
          required
          fullWidth
          autoFocus
          color={customization.tonicColor}
        />
        <TextField
          id="lastname"
          label={tr('accessRequest.form.lastname.label')}
          autocomplete="family-name"
          errorText={!state.lastname ? tr('accessRequest.error.missingLastName') : null}
          onChange={event => setState({...state, lastname: event.target.value})}
          required
          fullWidth
          color={customization.tonicColor}
        />
        <TextField
          id="userEmail"
          value={emailState.email}
          autocomplete="email"
          label={tr('accessRequest.form.email.label')}
          helperText={tr('accessRequest.form.email.helper')}
          errorText={emailState.emailError ? tr('accessRequest.error.' + emailState.emailError) : null}
          onChange={event => validateThenSetUserEmail(event.target.value)}
          required
          fullWidth
          color={customization.tonicColor}
        />

        {uiState.displayGroup && <Select
          id="group"
          label={customization.accessRequest.group.title[language]}
          onChange={event => setState({...state, group: event.target.value})}
          values={customization.accessRequest.group.items.map(item => ({ value: item[language], label: item[language]}))}
          fullWidth
        />}

        <div style={{textAlign: 'center'}}>
          <Button id="accessreq" label={tr('accessRequest.form.requestAnAccess')} loading={working} disabled={!state.firstname || !state.lastname || !emailState.email || Boolean(emailState.emailError)} onClick={() => dispatch(accessRequest({...state, email: emailState.email, cocoomAccountUri: auth.cocoomAccountUri}))}/>
        </div>
      </React.Fragment>
    );
  }

  function verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    // console.log(recaptchaToken, "<= your recaptcha token")
    // todo : send token to the backend to get a score from reCaptcha
  }

  function closeWindow() {
    const msTeamsOIDCSigninCookie = cookie.getCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
    cookie.resetCookie(cookie.MSTEAMS_OIDC_COOKIE_NAME);
    cookie.resetCookie(cookie.MSTEAMS_OIDC_REDIRECTION_COOKIE_NAME);

    if (msTeamsContext || msTeamsOIDCSigninCookie) {
      window.close();
      return null;
    }
  }

  return (
    <React.Fragment>
      <CenteredPanelPage
        backgroundColor={customization.backgroundColor}
        backgroundImage={customization.backgroundImage}
        headerBar={(
          <div style={{textAlign: 'right'}}>
            <LanguageSelector language={language} onChange={changeLanguage}/>
          </div>
        )}
        topLogo={customization.topLogo}
        topLogoAlt={customization.topLogoAlt}
        topLogoInPanel={customization.topLogoInPanel}
        imageAsTitle={customization.accessRequest.title.img}
        imageAsTitleAlt={customization.accessRequest.title.imgAlt}
        title={currentStep === 'done' ? '' : customization.accessRequest.title[language]}
        subtitle={currentStep === 'done' ? '' : customization.accessRequest.subtitle[language]}
      >
        {currentStep === 'failed' && getErrorAlert()}
        {(currentStep === 'form' || currentStep !== 'done') && getRequestAccessForm()}
        {currentStep === 'done' && getConfirmationView()}

        <div style={{textAlign: 'center'}}>
          {msTeamsContext &&
            <a href="." onClick={() => closeWindow()}>
              {tr('general.close')}
            </a>
          }

          { /* We must use an absolute path URL because some server side execution need to be done; it can't be a client-side relative hyperlink (such as  <Link>) */}
          {!msTeamsContext &&
            <a href={getMsTeamsOrStandalonePath('/signin' + (window.location.search ? window.location.search : ''), msTeamsContext)}>
              {tr('general.back')}
            </a>
          }
        </div>
      </CenteredPanelPage>
      {<ReCaptcha
        sitekey={getCaptchaKey()}
        action={'acccess_request'}
        verifyCallback={verifyCallback}
      />}
    </React.Fragment>
  );
}
