import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = customStyle => makeStyles(theme => {
  return {
    root: {
      ...customStyle.style,
      padding: theme.spacing(0, customStyle.internalSpacing)
    }
  };
})();

export default function PageColumn({ children, internalSpacing, style}) {
  const classes = useStyles({internalSpacing, style});
  return <Container classes={{root: classes.root}}>{children}</Container>;
}

PageColumn.propTypes = {
  internalSpacing: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
}

PageColumn.defaultProps = {
  internalSpacing: 0,
  style: {},
  children: <h1>Column content</h1>
}
