import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import France from './icons/country/France';
import GreatBritain from './icons/country/GreatBritain';

import { makeStyles } from '@material-ui/core/styles';
import { cocoomPrimaryColor } from './styles';

const useStyles = makeStyles({
  tab: {
    minWidth: 0
  },
  root: {
    display: 'inline-block'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 24,
      width: '100%',
      backgroundColor: cocoomPrimaryColor,
    }
  }
});

export default function LanguageSelector({language, onChange}) {
  const classes = useStyles();

  function handleChange(event, newValue) {
    onChange(newValue);
  }

  return (
    <Tabs
      value={language}
      onChange={handleChange}
      aria-label="Language selection"
      TabIndicatorProps={{ children: <div /> }}
      classes={{root: classes.root, indicator: classes.indicator}}
    >
      <Tab className={classes.tab} icon={<France />} aria-label="Français" value="fr"/>
      <Tab className={classes.tab} icon={<GreatBritain />} aria-label="English" value="en"/>
    </Tabs>
  );
}

LanguageSelector.propTypes = {
  language: PropTypes.oneOf(['en', 'fr']),
  onChange: PropTypes.func
}

LanguageSelector.defaultProps = {
  onChange: (language) => {}
}
