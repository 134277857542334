import React from 'react';
import PropTypes from 'prop-types';

import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { formFieldStyles } from './styles';

export default function Checkbox({id, label, checked, required, small, errorText, onChange}) {
  const classes = formFieldStyles({checkboxLabelFontSize: small ? 12 : undefined});
  return (
    <FormControl className={classes.checkbox} error={Boolean(errorText)}>
      <FormControlLabel label={label} control={
        <MuiCheckbox
          id={id}
          checked={checked}
          required={required}
          onChange={onChange}
          color="default"
          className={classes.checkboxIcon}
          icon={<CheckBoxOutlineBlankIcon fontSize={small ? 'small' : 'default'} />}
          checkedIcon={<CheckBoxIcon fontSize={small ? 'small' : 'default'} />}
        />
      }/>
      {Boolean(errorText) && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  small: PropTypes.bool,
  onChange: PropTypes.func
}

Checkbox.defaultProps = {
  label: 'A default label',
  required: false,
  checked: false,
  small: false,
  onChange: () => {}
}
