import React from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

const propTypes = {
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  disabled: PropTypes.bool,
  endIcon: PropTypes.element,
  href: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  startIcon: PropTypes.element,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained'])
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2,2)
  }
}));

export default function Button({
  color = 'primary',
  disabled = false,
  endIcon = null,
  href = null,
  id,
  label = 'A default label',
  loading = false,
  onClick = (event, value, hop) => console.log('Button was clicked', event, value, hop),
  size = 'medium',
  startIcon = null,
  variant = 'contained',
  }) {
  const classes = useStyles();
  const props = {color, disabled, endIcon, href, id, onClick, size, startIcon, variant};
  if (loading) {
    return (
      <CircularProgress color={color === 'default' ? 'inherit' : color} size={28} className={classes.root}/>
    );
  }

  return (
    <MuiButton
      {...props}
      className={classes.root}
    >
      {label}
    </MuiButton>
  );
};

Button.propTypes = propTypes;
