import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import CircularProgess from '../../components/CircularProgress';
import Register from '../Register';
import Typography from '../../components/Typography';
import Button from '../../components/Button';

import {
  setLanguage,
  setAzureTenantId,
  setEmail,
  setPricing
} from '../../reducers/register';

import { getMsTeamsContext } from '../../utils/getMsTeamsContext';
import * as cookie from '../../utils/cookieManager';


export default function SignUpForMsTeams() {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const [msTeamsContext, setMsTeamsContext] = useState(null);
  const currentPageLocation = useLocation();

  cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);


  function closeMsTeamsPopup() {
    window.close();
    return null;
  }

  function ConfirmationPage({gridWidth}) {
    return (
      <div style={{textAlign: 'center'}}>
        <img src="/ready.png" width={(gridWidth === 'xs') ? '100%' : 273} alt=""/>
        <Typography gutterBottom variant="h4">{t('register.allGood')}</Typography>
        <Typography gutterBottom pre variant="h6"><strong>{t('register.msteams.closeWindow')}</strong></Typography>
        <Typography gutterBottom pre variant="h6">{t('register.needHelp')}</Typography>
        <Button id="close" label={t('general.close')} onClick={closeMsTeamsPopup}/>
      </div>
    );
  }

  useEffect(() => {
    getMsTeamsContext({location: currentPageLocation,
      onContext: (context) => {
        setMsTeamsContext(context);
        i18n.changeLanguage(context.locale);
        dispatch(setLanguage(context.locale));
        dispatch(setAzureTenantId(context.tid));
        dispatch(setEmail(context.loginHint));
        dispatch(setPricing('teams'));
      }
    });
  }, [dispatch, i18n, currentPageLocation]);

  if (msTeamsContext) {
    // alert('REGISTER VIEW');
    return <Register noPassword noEditableEmail CustomConfirmationPage={ConfirmationPage}/>;
  }

  return  <CircularProgess fullPage/>;
}
