import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    root: {
      textAlign: 'left',
      marginBottom: theme.spacing(2)
    }
  }
});
const propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.oneOf(['n/a']), PropTypes.number]),
  message: PropTypes.string.isRequired,
  body: PropTypes.string,
  noContactUs: PropTypes.bool
};

export default function ApiErrorAlert({statusCode, message, body = undefined}) {
  const [tr] = useTranslation();
  const classes = useStyles();
  if (!statusCode) {
    return (
      <Alert severity="error" className={classes.root}>
        {body ? <strong>{message}</strong> : message}
        {body && <p>{body}</p>}
      </Alert>
    )
  }

  if (statusCode === 'n/a') {
    return (
      <Alert severity="error" className={classes.root}>
        <strong>{tr('error.withoutStatusCode', {message})}</strong>
        {body && <p>{body}</p>}
        <p>{tr('error.contactUs')}</p>
      </Alert>
    )
  }

  return (
    <Alert severity="error" className={classes.root}>
        <strong>{tr('error.withStatusCode', {code: statusCode, message})}</strong>
        {body && <p>{body}</p>}
        <p>{tr('error.tryAgainOrContactUs')}</p>
      </Alert>
  );
};

ApiErrorAlert.propTypes = propTypes;
