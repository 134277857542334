export function validateTitle(title) {
  if (!title || !title.trim()) {
    return 'empty';
  }

  if (title.length > 80) {
    return 'titleTooLong';
  }

  return null;
}

export function validateDomain(domain, domainAvailable) {
  if (!domain) {
    return 'empty';
  }

  if (!domain.match(/^[a-z0-9][a-z0-9-]*[a-z0-9]$/) && !domain.match(/^[a-z0-9]$/)) {
    return 'invalidCharacters';
  }

  if (domain.length > 40) {
    return 'domainTooLong';
  }

  if (domainAvailable === false) {
    return 'domainNotAvailable';
  }

  return null;
}

export function validateUsage(usage) {
  if (!usage) {
    return 'empty';
  }

  return null;
}

export function validateName(name) {
  if (!name) {
    return 'empty';
  }

  return null;
}

export function validateEmail(email) {
  if (!email) {
    return 'empty';
  }

  if (!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,5})+$/)) {
    return 'notEmail';
  }

  return null;
}

/* export function validatePassword(password) {
  if (!password) {
    return 'empty';
  }

  if (password.length < 8) {
    return 'passwordTooShort';
  }

  if (!password.match(/\p{Letter}/u)) {
    return 'passwordWithNoAlphanumerical';
  }

  if (!password.match(/[0-9]/)) {
    return 'passwordWithNoNumber';
  }

  if (!password.match(/\W|_/)) {
    return 'passwordWithNoSpecialChar';
  }

  return null;
}*/

export function validatePasswordConfirmation(password, passwordConfirmation) {
  if (!passwordConfirmation) {
    return 'empty';
  }

  if (password !== passwordConfirmation) {
    return 'passwordNotEqual';
  }

  return null;
}

export function validatePhoneNumber(phoneNumber) {
  if (!phoneNumber || !phoneNumber.trim()) {
    return 'empty';
  }

  if (!phoneNumber.match(/^\(?\+?\d+\)? ?\d+([ .-]?[()]?\d+)+$/)) {
    return 'notAPhoneNumber';
  }

  if (phoneNumber.trim().length < 4) {
    return 'notAPhoneNumber';
  }

  return null;
}

export function validateTerms(terms) {
  if (!terms) {
    return 'termsMustBeApproved';
  }

  return null;
}
