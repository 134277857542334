import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import AlignableImage from '../../components/AlignableImage';
import ApiErrorAlert from '../../components/ApiErrorAlert';
import Button from '../../components/Button';
import CenteredPanelPage from '../../components/CenteredPanelPage';
import LanguageSelector from '../../components/LanguageSelector';
import Typography from '../../components/Typography';

import {
  ASSOCIATION_REQUEST_ACTION_ID,
  associationRequest,
  SIGNOUT_ACTION_ID,
  signOut,
  resetRedirectionTargetAccountUrl
} from '../../reducers/auth';

import { getLanguage } from '../../utils/getLanguage';
import * as cookie from '../../utils/cookieManager';


export default function AssociationRequest({email, cocoomAccountUri, tid, target, onClose = () => {}}) {
  const [tr, i18n] = useTranslation();
  const language = getLanguage();
  const customization = useSelector(state => state.customization);
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const working = auth.actionState === 'working';
  // const [closing, setClosing] = useState(false);

  const currentStep = (auth.action === ASSOCIATION_REQUEST_ACTION_ID || auth.action === SIGNOUT_ACTION_ID) ? auth.actionState : 'form';


  useEffect(() => {
    if (currentStep === 'done') {
      cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
    }
  }, [currentStep]);

  /* useEffect(() => {
    if (closing) {
      alert('Closing, so sign user out');
      dispatch(signOut());
    }
  }, [dispatch, closing]); */

  useEffect(() => {
    if (!auth.user || (auth.action === SIGNOUT_ACTION_ID && auth.actionState === 'done')) {
      // alert('onClose called');
      onClose();
    }
  }, [dispatch, auth.user, auth.action, auth.actionState, onClose]);


  function handleCancel() {
    // alert('Cancel Assoc. request viex');
    dispatch(resetRedirectionTargetAccountUrl());
    cookie.resetCookie(cookie.MSTEAMS_ASSOCIATION_COOKIE_NAME);
    // setClosing(true);
    // alert('Closing, so sign user out');
    dispatch(signOut());
  }

  function changeLanguage(language) {
    i18n.changeLanguage(language);
  }

  function getConfirmationView() {
    return (
      <React.Fragment>
        <AlignableImage align="center" src="/send.svg" alt="Message sent" imgStyle={{width: 100, height: 100}}/>
        <Typography align="center" altFont>{tr('associationRequest.mailSentMessage')}</Typography>
        <div style={{textAlign: 'center'}}>
          <Button id="close" label={tr('general.close')} onClick={handleCancel}/>
        </div>
      </React.Fragment>
    );
  }

  function getErrorAlert() {
    return (
      <React.Fragment>
        <ApiErrorAlert statusCode={auth.actionError.statusCode} message={auth.actionError.text} body={auth.actionError.body}/>
      </React.Fragment>
    );
  }

  function getRequestAccessForm() {
    return (
      <React.Fragment>
        <Typography component="p">{tr('associationRequest.text')}</Typography>
        <Typography component="ul">
          <li><strong>{tr('associationRequest.email')}</strong>{email}</li>
          <li><strong>{tr('associationRequest.cocoomAccountUri')}</strong>{cocoomAccountUri}</li>
          <li><strong>{tr('associationRequest.tid')}</strong>{tid}</li>
        </Typography>
        <div style={{textAlign: 'center'}}>
          <Button id="assocaccount" label={tr('associationRequest.requestAssociation')} loading={working} onClick={() => dispatch(associationRequest({email, cocoomAccountUri, tid, target}))}/>
        </div>
        {!working && <div style={{textAlign: 'center'}}>
          <Button id="cancel" label={tr('general.cancel')} variant="text" onClick={handleCancel}/>
        </div>}
      </React.Fragment>
    );
  }

  return (
    <CenteredPanelPage
      backgroundColor={customization.backgroundColor}
      backgroundImage={customization.backgroundImage}
      headerBar={(
        <div style={{textAlign: 'right'}}>
          <LanguageSelector language={language} onChange={changeLanguage}/>
        </div>
      )}
      topLogo={customization.topLogo}
      topLogoAlt={customization.topLogoAlt}
      topLogoInPanel={customization.topLogoInPanel}
      title={currentStep === 'done' ? '' : tr('associationRequest.title')}
      subtitle={currentStep === 'done' ? '' : tr('associationRequest.subtitle')}
    >
      {currentStep === 'failed' && getErrorAlert()}
      {(currentStep === 'form' || currentStep !== 'done') && getRequestAccessForm()}
      {currentStep === 'done' && getConfirmationView()}
    </CenteredPanelPage>
  );
}
